import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import axios from "axios";

const store = createStore({
    state: {
        user: null,
        cart: [],
        products: [],
        country:null,
        catagory:null,
        brand:null,
        countries:[],
        brands:[],
        catagories:[],
        page:1,
        total:0,
        genders:["All","Men","Women"],
        gender:null,
        loaded:false,
        product:[],
    },
    mutations: {
        loginUser(state, payload) {
            state.user = payload;
        },
        logoutUser(state) {
            state.user = null;
        },
        addToCart(state, payload) {
            if (state.cart.length != 0) {
                //check if the item is in the cart
                let item = state.cart.find((item) => item._id === payload._id);

                //if the item is in the cart and has the same id and size, increase the quantity
                if (item) {
                    if (item.size === payload.size) {
                        //if the item's quantity is less than 10 add more
                        if (item.quantity < 10) {
                            item.quantity += payload.quantity;
                        } else if (item.quantity >= 10) {
                            //if the item is already 10, don't add more
                            item.quantity = 10;
                        }
                    } else {
                        //if the item exists but has a different size, add the new item
                        console.log("different size");
                        state.cart = [...state.cart, payload];
                    }
                } else {
                    //if the item is not in the cart, add it
                    state.cart = [...state.cart, payload];
                }
            } else {
                //if the cart is empty, add the item
                state.cart = [...state.cart, payload];
            }
        },
        deleteItem(state, payload) {
            let index = state.cart.indexOf(payload);
            state.cart.splice(index, 1);
        },
        updateQuantity(state, payload) {
            // increase the quantity of the item in the cart
            if (payload.quantity > 0) {
                let item = state.cart.find(
                    (item) =>
                        item._id === payload._id && item.size === payload.size
                );
                item.quantity = payload.quantity;
            }
        },
        emptyCart(state) {
            state.cart = [];
        },
        setProducts(state, payload) {
            state.products = payload;
        },
        setCountries(state, payload) {
            state.countries = payload;
        },
        setCatagories(state,payload){
            state.catagories = payload;
        },
        setCountry(state, country) {
            state.country = country;
            console.log(country);
          },
        setCatagory(state,catagory){
            state.catagory = catagory;
        },
        setBrands(state,payload){
            state.brands = payload
        },
        setBrand(state,brand){
            
            state.brand = brand;
        },
        setProducts(state,payload){
            state.products = payload;
        },
        setProduct(state,payload){
            console.log(setProduct)
            state.product = payload
        },

        setCurrentPage(state,payload){
            state.page = payload;
        },
        setTotalPage(state,payload){
            state.total= payload;
        },
        setGenders(state,payload){
            state.genders = payload;
        },
        setGender(state,gender){
            state.gender = gender;
        }
    
    },
    actions: {
        set_user(context, payload) {
            context.commit("loginUser", payload);
        },
        remove_user(context) {
            context.commit("logoutUser");
        },
        add_to_cart(context, payload) {
            context.commit("addToCart", payload);
        },
        delete_item(context, payload) {
            context.commit("deleteItem", payload);
        },
        update_quantity(context, payload) {
            context.commit("updateQuantity", payload);
        },
        clear_cart(context) {
            context.commit("emptyCart");
        },
        set_products(context, payload) {
            context.commit("setProducts", payload);
        },
        set_product(context,payload){
            context.commit("setProduct",payload)
        },

         get_genders(context){
            try{
                context.commit("setGenders",this.state.genders);
                
                console.log("Hello");
            } catch(error){
                console.error('Error fetching genders:', error);

            }
        },
        set_gender(context,payload){
            context.commit("setGender",payload);
            context.dispatch("handle_catagory",this.state.catagory)

        },

        async get_countries(context) {
            //https://store.speakroom.io:3000/api/countries
            //alert("get_countries");
            try {
               const response = await axios.get('https://store.speakroom.io:3000/api/countries');
                context.commit('setCountries', response.data);
                 // Assuming you have a mutation to set countries in the store
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        },
        async handle_country(context,payload){
            context.commit("setCountry",payload)
            context.dispatch("get_genders");
            // console.log(this.state.country);
            const response = await axios.get(`https://store.speakroom.io:3000/api/categories/${this.state.country}`);
            context.commit("setGender",this.state.genders[0]);

            context.commit("setCatagories",response.data);
            context.commit("setCatagory",response.data[0]);
            if(response.data[0]){
                context.dispatch("handle_catagory",response.data[0]);
            }
            context.commit("setCurrentPage" , 1);

            context.dispatch("get_products")

        },
         async handle_catagory(context,payload){
            context.commit("setCatagory",payload)
            let url = `https://store.speakroom.io:3000/api/brands?country=${this.state.country}&productType=${this.state.catagory}`
            if(this.state.gender != "All") {
                url = url+`&gender=${this.state.gender.toLowerCase()}`
            }
            const response1 =await  axios.get(url);
            context.commit("setCurrentPage" , 1);
            
            context.commit("setBrands",response1.data);  
            // if(this.state.brand){
                // console.log(this.state.brand);
                context.commit("setBrand",response1.data[0]);
                            // }
                            // console.log(this.state.brand);
            context.dispatch("get_products")

        },
        async handle_Brand(context,payload){
            // console.log(payload);
            context.commit("setBrand",payload);
            context.commit("setCurrentPage" , 1);
            context.dispatch("get_products")
            
        },
        async get_products(context) {
            let response1;
            this.state.loaded = false;
            if(this.state.gender === "All"){
                 response1 =await  axios.get(`https://store.speakroom.io:3000/api/products?country=${this.state.country}&productType=${this.state.catagory}&limit=40&page=${this.state.page}${this.state.brand  == "All" ? "" : "&brandName="+this.state.brand  }`);
            }
            else if(this.state.brand === "All" || this.state.brand==null){
                response1 =await  axios.get(`https://store.speakroom.io:3000/api/products?country=${this.state.country}&productType=${this.state.catagory}&limit=40&page=${this.state.page}&gender=${this.state.gender.toLowerCase()}`);
           }
            else{
                 response1 =await  axios.get(`https://store.speakroom.io:3000/api/products?country=${this.state.country}&productType=${this.state.catagory}&limit=40&gender=${this.state.gender.toLowerCase()}&page=${this.state.page}&brandName=${this.state.brand}`);

            }
            if(response1){
                this.state.loaded=true;
            }
            // console.log(response1.data.products);
            context.commit("setProducts",response1.data.products);
            context.commit("setCurrentPage",response1.data.currentPage);
            context.commit("setTotalPage",response1.data.totalProducts);

        },
        async get_catagories(context){
            try{
                context.commit("setCurrentPage" , 1);
                
                context.commit("setCatagories",["shoes","shirst"]);

            }catch (error){
                console.error('Error fetching Catagories:', error);

            }
        },
        async set_page(context, payload) {
            try{
                
                context.commit("setCurrentPage" , payload);
                context.dispatch("get_products")


            }catch (error){
                console.error('Error fetching Catagories:', error);

            }
        }


    },
    getters: {
        subtotal: (state) => {
            let subtotal = 0;
            state.cart.forEach((item) => {
                subtotal += item.price * item.quantity;
            });
            return subtotal;
        },
        getHash: (state) => {
            var md5 = require("blueimp-md5/js/md5");
            var hash = md5(state.user.email);
            return `https://www.gravatar.com/avatar/` + hash + `?d=mp`;
        },
    },
    plugins: [createPersistedState()],
});

//export store
export default store;
