<template>
    <!-- <main-header /> -->
    <!-- <sub-header
        heading="#stayhome"
        subHeading="Save more with coupons up to 70% off"
    /> -->

    <router-view />
    <Model
      v-if="ifModel_Country_Selected()"
      @close="closeModal"
    />
    
      <VaCarousel class="caro"
    :items="items"
    stateful
    autoscroll
    infinite
  >
    <template #default="{ index }">
      <!-- {{ index + 1 }} -->
      <VaImage class="w-full md:w-1/2 lg:w-1/3" :src="items[index]"  />
    </template>
  </VaCarousel>
  
    <div  class="container-chips">
    <div class="chips-row">
        <h3 style="font-size: medium;color: #344050;">Gender:</h3>
    </div> 

    <div class="chips-row" >
       
      <chip class="chips-item"  v-for="(gender,index ) in genders" :key="index" :label="gender" :class='{active: genderindex === index}'  @click="handle_gender(gender,index)" ></chip>
      <!-- Add more chips as needed -->
      <!-- <chip label="Shoes"></chip> -->
      <!-- <chip label="Shirt"></chip> -->
      <!-- Add more chips as needed -->
    </div>
    <VaDivider />

    <div class="chips-row">
        <h3 style="font-size: medium;color: #344050;">Category:</h3>
    </div> 
    <div class="chips-row">
      <chip class="chips-item"  v-for="(cat,index ) in catagories" :key="index" :label="cat" :class='{active: cataactiveIndex === index}'  @click="activateChip(cat,index),handleCata()" ></chip>
      <!-- Add more chips as needed -->
      <!-- <chip label="Shoes"></chip> -->
      <!-- <chip label="Shirt"></chip> -->
      <!-- Add more chips as needed -->
    </div>
    <VaDivider />

    <div class="chips-row">
        <h3 style="font-size: medium;color: #344050;">See Only Sales On:</h3>
    </div> 
    <div class="chips-row">
      <chip  class="chips-item"  v-for="(brand,index ) in brands" :key="index" :label="brand" :class='{active: this.brand === brand}'  @click="handleBrand(brand,index)" ></chip>
      <!-- Add more chips as needed -->
      <!-- <chip label="Shoes"></chip> -->
      <!-- <chip label="Shirt"></chip> -->
      <!-- Add more chips as needed -->
    </div>
    
    <VaDivider />
</div>
    <div class="container">
        <div class="product__container" v-if="loaded">
            
            <product-card
                v-for="(product, index) in products"
                :key="index"
                :productId="product._id"
                :productName="product.productName"
                :brand="product.brandName"
                :price="product.salePrice"
                :currency="product.currencySymbol"
                :ratings="product.rating"
                :image_url="product.images[0]"
                :in_stock="true"
                :actualPrice="product.actualPrice"
            />
        </div>
        <product-preloader v-else> Loading products... </product-preloader>
    </div>
    <div class="pagination">
        <vue-awesome-paginate
            :total-items="total"
            :max-pages-shown="10"
            :items-per-page="40"
            v-model="page"
            :show-breakpoint-buttons="true"
            :on-click="fetchNewPage"
            paginate-buttons-class="btn"
            active-page-class="btn-active"
            back-button-class="back-btn"
            next-button-class="next-btn"
            :hide-prev-next-when-ends="true"
        >
            <template #prev-button>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-left"
                    viewBox="0 0 16 16"
                >
                    <path
                        fill-rule="evenodd"
                        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                    />
                </svg>
            </template>
            <template #next-button>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    fill="currentColor"
                    class="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                >
                    <path
                        fill-rule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    />
                </svg>
            </template>
        </vue-awesome-paginate>
    </div>

    <div class="footer">
        <button @click="$router.push('/termsandcondition/')" class="footerbtn">Terms And Condtion</button>
        <button @click="$router.push('/privacypolicy/')"  class="footerbtn">Privacy Policy</button>
    </div>

    
</template>

<script>
import SubHeader from "@/components/SubHeader.vue";
import ProductCard from "@/components/home_components/cards/ProductCard.vue";
import ProductPreloader from "@/components/preloaders/ProductPreloader.vue";
import chip from "@/components/ChipsModel.vue";
import MainHeader from "@/components/MainHeader.vue";
import Model from '@/views/User/SelectCountryModel.vue';

// import MainFooter from "@/components/MainFooter.vue";

import axios from "axios";
import { mapState,mapActions } from 'vuex';
export default {
    name: "ShopView",
    components: {
        Model,
        SubHeader,
        ProductCard,
        MainHeader,
        // MainFooter,
        chip,
        
        "product-preloader": ProductPreloader,
    },
    data() {

        return {
            // page: 1,
            // loaded: false,
            // total: 0,
            iscountry:null,
            
            catagory:null,
            cataactiveIndex: 0,
            brandIndex:0,
            genderindex:0,
            branda:null,
            gender:null,
            items:["https://images.pexels.com/photos/322207/pexels-photo-322207.jpeg","https://images.pexels.com/photos/298863/pexels-photo-298863.jpeg?cs=srgb&dl=pexels-terje-sollie-298863.jpg&fm=jpg"]
        };
    },
    created() {
        this.getProducts();

        // this.activateChip();
       if(this.country){
        this.handle_country(this.country);
       }
        
       
    },
    computed: {
        ...mapState(["catagories","catagory","brands","brand","products","genders","gender","page","total","loaded","country"]),
    },
    methods: {
        ...mapActions(["handle_catagory","handle_Brand","set_page","get_genders","set_gender","handle_country"]),
        showModal() {
        this.isModalVisible = this.ifModel_Country_Selected();
      },
      closeModal() {
        this.isModalVisible = false;
        sessionStorage.setItem("country",true);
        const data  = sessionStorage.getItem("country");
        console.log(data);
      },
       async  handleCata(){
            // console.log(this.catagory);
          await this.handle_catagory(this.catagory);
        },
        async handleBrand(bran,index){
        //    console.log(bran);
            this.branda = bran;
           await  this.handle_Brand(this.branda);
        },
        getProducts() {
            // this.loaded = true;
           
        },
        fetchNewPage(page) {
            this.page = page;
            this.set_page(page)
            this.getProducts();
            window.scrollTo(0, 0);
        },

        activateChip(cata,index) {
      this.cataactiveIndex = index;
        
      this.catagory = cata;
         // Set the activeIndex to the index of the clicked chip
    },
    handle_gender(gender,index){
        this.genderindex = index;
        this.gender = gender;
        this.set_gender(this.gender);
    },
    ifModel_Country_Selected() {
        return sessionStorage.getItem("country") != "true"
    }
    },
};
</script>

<style >
    .test{
        --va-button-font-size:1.2rem;
    }
 /* .chip.isactive{
    background-color:black;
    color: white;
 } */
.btn {
    border: none;
    background-color: #f2f2f2;
    padding-inline: 20px;
    height: 40px;
    border: 2px solid var(--grey-2);
    font-size: 1.7rem;
    margin-inline: 5px;
    cursor: pointer;
}

.back-btn,
.next-btn {
    background-color: #f2f2f2;
    color: black;
}

.btn:hover {
    background-color: #083e46;
    border-color: #083e46;
    color: white;
}

.btn-active {
    background-color: #083e46;
    color: white;
    border-color: #083e46;
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
}
.chips-row{
    display:flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.chips-item{
   
    margin: 1%;
    margin-left: 0;
    
}
.container-chips  {
    margin-left: 2%;
    margin-top: 10px;
}
ul#componentContainer {
    -webkit-padding-start: 0;
    display: flex  !important;
    flex-wrap: wrap ;
}
.caro{
    z-index: -1;
}
.footer{
    display: flex;
    justify-content: space-around;
    background-color:black;
}
.footerbtn{
    color:white;
    background-color:black;
}
</style>
