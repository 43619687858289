

<template>
    <!-- <div class="modal-backdrop">
      <div class="modal">
        <header class="modal-header">
          <slot name="header">
            This is the default title!
          </slot>
          <button
            type="button"
            class="btn-close"
            @click="close"
          >
            x
          </button>
        </header>
  
        <section class="modal-body">
          <slot name="body">
            This is the default body!
          </slot>
         </section>
  
        <footer class="modal-footer">
          <slot name="footer">
            This is the default footer!
          </slot>
          <button type="button" class="btn-green" @click="close">
            Close Modal
          </button>
        </footer>
      </div>
    </div> -->

    
 <!-- <div class="modal-backdrop">
      <div class="modal">
      <select v-model="selectedCountry">
        <option value="" disabled>Select a country</option>
        <option v-for="country in countries" :key="country" :value="country">
          {{ country }}
        </option>
      </select>
      <button @click="handleSelect">Select</button>
      <button @click="close">Close</button>
    </div>

  </div> -->


  <VaModal
    v-model="showModal"
    hide-default-actions
    size="auto"
    noDismiss
    noEscDismiss
    noOutsideDismiss
    
    :mobile-fullscreen="false"
  >
    <h3 class="va-h3">
      Select your location 
    </h3>
   <div class="center">
    <VaSelect
      v-model="selectedCountry"
      placeholder="select Country"
      label="country"
      color="#008765"
      :options="countries"
      inner-label
    />
  </div>

      <template #footer>
        <div class="modal-footer">
          <div class="button-container">
            <VaButton class="ml-1" color="#008765" @click="handleSelect">Select</VaButton>
          </div>
        </div>
      </template>
    
  </VaModal>

  </template>
  
  <script>
  import { mapState,mapActions } from 'vuex';

  export default {
    name: 'Modal2',
    data() {return{
      selectedCountry: null,
      showModal:true,
    }},
    computed: {
       ...mapState(['countries'])
     },
    methods: {
      ...mapActions(['handle_country']),
      close() {
        this.showModal=false;
      },
      handleSelect(value)  {
          console.log(this.selectedCountry);
          if(this.selectedCountry){
            this.handle_country(this.selectedCountry);
            this.close();
          }
          else{
            console.error('Please select a country');

          }
      }
    },
  };
</script>

<style>
 

  .va-modal__footer, .center {
  display: flex ;
  justify-content: center !important;
  align-items: center;
  }

  .button-container {
      margin-top: 20px; /* Adjust as needed */
   }
   .va-modal--mobile-fullscreen {
   position: fixed !important;
}

</style>