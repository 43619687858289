<template>
    <div class="chip" :class="{ active: isActive }" @click="toggleActive">
      {{ label }}
    </div>
  </template>
  
  <script>
  export default {
    props: {
      label: String,
    },
    data() {
      return {
        isActive: false,
      };
    },
    methods: {
      toggleActive() {
        // this.isActive = !this.isActive;
        this.$emit("active")
      },
    },
  };
  </script>
  
  <style scoped>
  .chip {
    display: inline-block;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .active {
    background-color: #008765;
    color: white;
  }
  </style>
  